import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

function Header(props) {
  const {
    location: { pathname },
    // logOut,
  } = props;

  const navDefault = "link dim gray f6 f5-l dib mr3 mr4-l";
  const navSelected = `${navDefault} ba-0 bb bw1 b--lightest-blue`;
  const navItems = ["overview", "background", "methodology", "timeline", "500books", "contact"];
  const isSelected = (nav) => {
    // const re = new RegExp(`^/${nav}`);
    return pathname.match(RegExp(`^/${nav}`));
  };

  return (
    <nav
      id="headerNav"
      className="pa3 pa4-ns fixed bb w-100 bg-washed-blue o-90 db dt-l z-999 lh-title"
    >
      <a className="link dim black b f6 f5-ns dib mr3 mr4-l" href="/" title="home">
        <span>National</span>
        <span className="dark-blue">Book</span>
        <span className="navy">Collection</span>
        <span className="black-70">.org</span>
      </a>
      {navItems.map((nav) => (
        <a
          key={nav}
          className={isSelected(nav) ? navSelected : navDefault}
          href={`/${nav}`}
          title={nav}
        >
          {nav === "500books" ? "500+ books" : nav}
        </a>
      ))}
      {/*
      <a
        href={pathname}
        className="fr link dim gray f6 f5-ns dib"
        title="Sign Out"
        onClick={logOut}
      >
        sign out
      </a>
      */}
      {/*
      <button
        type="button"
        className="fr dim navy f6 f5-l dib pointer outline-0 shadow-none bn bg-transparent"
        onClick={logOut}
      >
        sign out
      </button>

      */}
    </nav>
  );
}
Header.propTypes = {
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  // logOut: PropTypes.func.isRequired,
};

export default withRouter(Header);
