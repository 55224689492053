// import "lato-font";
// import "typeface-merriweather";
// import "typeface-merriweather-sans";
import "typeface-faustina";
import "typeface-inter";

import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Header from "screens/UI/Header";
// import Login from "screens/UI/Login";

// const dbRemote = false;

const ScreensArticles = lazy(() => import("screens/Articles"));
const ScreensBooklist = lazy(() => import("screens/Booklist"));
const ScreensContact = lazy(() => import("screens/Contact"));

function ScreensRoot() {
  /*
  const [dialogOpen, setDialogOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("li") ? JSON.parse(localStorage.getItem("li")) : false
  );

  const logOut = () => {
    setLoggedIn(false);
    localStorage.setItem("li", JSON.stringify(false));
  };

  useEffect(() => {
    if (!loggedIn && !dialogOpen) {
      setMsg("");
      setDialogOpen(true);
      return;
    }
    if (loggedIn && dialogOpen) {
      setMsg("");
      setDialogOpen(false);
    }
  }, [dialogOpen, loggedIn]);

  const checkLogin = useCallback(async (e, user, pass) => {
    e.preventDefault();
    if (user && pass) {
      dbRemote = new PouchDB(`https://${user}:${pass}@couchdb3.prtd.app/anc5`);
      try {
        await dbRemote.info();
      } catch (error) {
        // user/pass is wrong, or network connectivity issues
        // assume we enjoy network connectivity (deal with that
        // set of problems later)
        setMsg("That username and password combination is not correct.");
        return;
      }
      setMsg("");
      setLoggedIn(true);
      localStorage.setItem("li", JSON.stringify(true));
      return;
    }
    if (user === "" || pass === "") {
      setMsg("Please enter a username and a password.");
      return;
    }
    setMsg("That username and password combination is not correct.");
  }, []);
  */

  return (
    <Router>
      {/*
      <Login dialogOpen={dialogOpen} msg={msg} checkLogin={checkLogin} />
      {loggedIn && (
        <>
          <Header logOut={logOut} />
      */}
      <Header />
      <Suspense fallback={<div className="tc pa4">...</div>}>
        <Switch>
          <Route path="/overview" component={ScreensArticles} />
          <Route path="/background" component={ScreensArticles} />
          <Route path="/methodology" component={ScreensArticles} />
          <Route path="/timeline" component={ScreensArticles} />
          <Route path="/500books" component={ScreensBooklist} />
          <Route path="/contact" component={ScreensContact} />
          <Route render={() => <Redirect push to="/overview" />} />

          {/*
                  <Route path="/overview" component={ScreensOverview} />
                  <Route path="/background" component={ScreensBackground} />
                  <Route path="/methodology" component={ScreensMethodology} />
                  <Route path="/timeline" component={ScreensTimeline} />
                  <Route render={() => <Redirect push to="/overview" />} />
                */}
        </Switch>
      </Suspense>
      {/*
        </>
      )}
      */}
    </Router>
  );
}

export default ScreensRoot;
